import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormLabel, Nav, Row, Table, } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from "react-ga4";
import ModalTicket from "./modal/ModalTicket";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Game Schedule',
});

const GameScheduleAll = () => {
    const [gameSchedule, setGameSchedule] = useState([]);
    // const [competition, setCompetition] = useState([]);
    const [season, setSeason] = useState([]);
    const [standing, setStanding] = useState([]);

    // const [competition_id, setCompetitionId] = useState('');
    const [season_id, setSeasonId] = useState('');
    const [opt, setOpt] = useState('next');

    const [show, setShow] = useState(false);
    const [linkUmum, setLinkUmum] = useState('');
    const [linkPassport, setLinkPassport] = useState('');
    const [linkBiru, setLinkBiru] = useState('');
    const [daysPass, setDaysPass] = useState('');
    const [daysPass2, setDaysPass2] = useState('');
    const [gameId, setGameId] = useState('');
    const [gameDaysPass, setGameDayspass] = useState([]);
    const [gameDaysPass2, setGameDayspass2] = useState([]);

    // useEffect(() => {
    //     getGameSchedule(competition_id, season_id);
    //     getSeason();
    //     getStanding();

    //     if (season_id) {
    //         getCompetition(season_id);
    //     }
    // }, [competition_id, season_id]);

    useEffect(() => {
        getGameSchedule(season_id, opt);
        getSeason();
        getStanding(season_id);
        getTicketPass();
    }, [season_id, opt]);

    // const getGameSchedule = async (competition_id, season_id) => {
    //     try {
    //         const response = await axios.get(process.env.REACT_APP_SITE + `/schedules_join_all?season=${competition_id}&year=${season_id}`);
    //         setGameSchedule(response.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const getGameSchedule = async (season_id, opt) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/schedules_join_all?year=${season_id}&opt=${opt}`);
            setGameSchedule(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    // const getCompetition = async (season_id) => {
    //     try {
    //         const response = await axios.get(process.env.REACT_APP_SITE + `/competition_by_season?year=${season_id}`);
    //         setCompetition(response.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const getSeason = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/season_all');
            setSeason(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const linkTicketStream = (game) => {
        if (game.status === 2 || game.status === 3) {
            if (game.ticket && (game.stream || game.stats)) {
                if (game.stream && game.stats) {
                    if (game.ticket_passport || game.ticket_biru) {
                        return (
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '5px 0 0 -70px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '5px 0 0 -3px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '5px 0 0 31px' }}>STATS <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '5px 0 0 -70px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '5px 0 0 -3px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '5px 0 0 31px' }}>STATS <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    }
                } else if (game.stream) {
                    if (game.ticket_passport || game.ticket_biru) {
                        return (
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '5px 0 0 -50px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '5px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '5px 0 0 -50px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '5px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    }
                } else if (game.stats) {
                    if (game.ticket_passport || game.ticket_biru) {
                        return (
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '5px 0 0 -53px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '5px 0 0 14px' }}>STATS <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '5px 0 0 -53px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '5px 0 0 14px' }}>STATS <span className='iconLive'>&#8226;</span></Button >
                            </>
                        );
                    }
                }
            } else if (game.ticket) {
                if (game.ticket_passport || game.ticket_biru) {
                    return (
                        <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '5px 0 0 -33px' }}>BUY TICKET</Button>
                    );
                } else {
                    return (
                        <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '5px 0 0 -33px' }}>BUY TICKET</Button>
                    );
                }
            } else if (game.stream) {
                return (
                    <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '5px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
                );
            }
        }
    }

    const linkHighlight = (game) => {
        if (game.highlight) {
            return (
                <Button size="sm" className='btnLink' href={game.highlight} target='_blank' style={{ margin: '5px 0 0 -33px' }}>HIGHLIGHT</Button>
            );
        }
    }

    const gameScheduleTable = (game, index) => {
        // status game
        const gameStatus = ['', 'RESULT', 'NEXT', 'UPCOMING', 'POSTPONED'];
        let statusGame = gameStatus[game.status];

        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let date = new Date(game.match_date);

        let day = days[date.getDay()];
        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = day + ' ' + m + ' ' + d + ' , ' + y + ' | ' + t + ' WIB | ' + game.venue;

        return (
            <React.Fragment key={index}>
                {game.status === 1 ?
                    <>
                        <tr>
                            <td colSpan={5} style={game.status === 4 ? { textDecorationLine: 'line-through' } : { textDecorationLine: 'none' }}>{convertDateTime}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '35%', fontSize: '13px' }} className='pb-3'><img src={process.env.REACT_APP_SITE_IMAGE + game.team1_url} alt='team1' style={{ width: '70px' }} /><br />{game.team1}</td>
                            <td style={{ width: '10%' }} className='align-middle pb-3 fs-1 header-title'>{game.team1_score}</td>
                            <td style={{ width: '10%' }} className='align-middle pb-3'>
                                <img src={process.env.REACT_APP_SITE_IMAGE + game.competition_url} alt='competition' className='competitionImg' /><br />
                                <h6>VS</h6>
                                {statusGame}
                                <div className='btnLinkLive'>
                                    {linkHighlight(game)}
                                </div>
                            </td>
                            <td style={{ width: '10%' }} className='align-middle pb-3 fs-1 header-title'>{game.team2_score}</td>
                            <td style={{ width: '35%', fontSize: '13px' }} className='pb-3'><img src={process.env.REACT_APP_SITE_IMAGE + game.team2_url} alt='team2' style={{ width: '70px' }} /><br />{game.team2}</td>
                        </tr>
                    </>
                    :
                    <>
                        <tr>
                            <td colSpan={3} style={game.status === 4 ? { textDecorationLine: 'line-through' } : { textDecorationLine: 'none' }}>{convertDateTime}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '35%', fontSize: '13px' }} className='pb-3'><img src={process.env.REACT_APP_SITE_IMAGE + game.team1_url} alt='team1' style={{ width: '70px' }} /><br />{game.team1}</td>
                            <td style={{ width: '10%' }} className='align-middle pb-3'>
                                <img src={process.env.REACT_APP_SITE_IMAGE + game.competition_url} alt='competition' className='competitionImg' />
                                <h6>VS</h6>
                                {statusGame}
                                <div className='btnLinkLive'>
                                    {linkTicketStream(game)}
                                </div>
                            </td>
                            <td style={{ width: '35%', fontSize: '13px' }} className='pb-3'><img src={process.env.REACT_APP_SITE_IMAGE + game.team2_url} alt='team2' style={{ width: '70px' }} /><br />{game.team2}</td>
                        </tr>
                    </>
                }
            </React.Fragment>
        )
    }

    const getStanding = async (season_id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/standing?year=${season_id}`);
            setStanding(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelect = (eventKey) => {
        setOpt(eventKey);
    };

    const handleShow = (e, umum = '', passport = '', biru = '', gameId = '') => {
        e.preventDefault();
        setShow(true)
        setLinkUmum(umum);
        setLinkPassport(passport);
        setLinkBiru(biru);
        setGameId(gameId);
    };

    const getTicketPass = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/ticket_pass');
            setDaysPass(response.data[0].days_pass);
            setDaysPass2(response.data[0].days_pass_2);
            setGameDayspass(response.data[0]['game_days_pass'] || []);
            setGameDayspass2(response.data[0]['game_days_pass_2'] || []);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-body-tertiary" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <Row className='pb-5'>
                    <Col lg={8} className='pb-5'>
                        <FormLabel className='fs-3 header-title'>GAME SCHEDULE</FormLabel>
                        <Row className='mt-3'>
                            {/* <Col lg={9}>
                                <Form.Select value={competition_id} onChange={(e) => setCompetitionId(e.target.value)}>
                                    <option hidden value={''}>Competition</option>
                                    {competition.map((competition, index) => (
                                        <option value={competition.id} key={index}>{competition.name}</option>
                                    ))}
                                </Form.Select>
                            </Col> */}
                            <Col lg={3}>
                                <Form.Select value={season_id} onChange={(e) => setSeasonId(e.target.value)}>
                                    <option hidden value={''}>Season</option>
                                    {season.map((season, index) => (
                                        <option value={season.id} key={index}>{season.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Nav className='mt-3' variant='tabs' defaultActiveKey='next' onSelect={handleSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey='next'>NEXT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey='result'>RESULT</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Table striped className='mt-3 tbl-game-schedule'>
                            <tbody>
                                {gameSchedule.map((game, index) => (
                                    gameScheduleTable(game, index)
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={4}>
                        <FormLabel className='fs-3 header-title'>IBL STANDING</FormLabel>
                        <Table className='mt-3' striped bordered hover style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th>RANK</th>
                                    <th>TEAM</th>
                                    <th>G</th>
                                    <th>W</th>
                                    <th>L</th>
                                    <th>PTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {standing.map((standing, index) => (
                                    standing.team === 'Prawira Harum Bandung' || standing.team === 'Prawira Bandung' ?
                                        <tr key={index}>
                                            <td style={{ backgroundColor: '#003399' }}>{standing.rank}</td>
                                            <td className='text-start fw-bold' style={{ backgroundColor: '#003399' }}>{standing.team.toUpperCase()}</td>
                                            <td style={{ backgroundColor: '#003399' }}>{standing.game}</td>
                                            <td style={{ backgroundColor: '#003399' }}>{standing.win}</td>
                                            <td style={{ backgroundColor: '#003399' }}>{standing.lose}</td>
                                            <td style={{ backgroundColor: '#003399' }}>{standing.point}</td>
                                        </tr>
                                        :
                                        <tr key={index}>
                                            <td>{standing.rank}</td>
                                            <td className='text-start'>{standing.team}</td>
                                            <td>{standing.game}</td>
                                            <td>{standing.win}</td>
                                            <td>{standing.lose}</td>
                                            <td>{standing.point}</td>
                                        </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <ModalTicket
                show={show}
                close={() => { setShow(false) }}
                linkUmum={linkUmum}
                linkPassport={linkPassport}
                linkBiru={linkBiru}
                daysPass={daysPass}
                daysPass2={daysPass2}
                gameId={gameId}
                gameDaysPass={gameDaysPass}
                gameDaysPass2={gameDaysPass2} />
        </div>
    );
}

export default GameScheduleAll;
