import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormLabel, Nav, Row } from 'react-bootstrap';
import axios from 'axios';
import ModalTicket from "./modal/ModalTicket";

const GameSchedule = () => {
    const [gameSchedule, setGameSchedule] = useState([]);
    const [show, setShow] = useState(false);
    const [linkUmum, setLinkUmum] = useState('');
    const [linkPassport, setLinkPassport] = useState('');
    const [linkBiru, setLinkBiru] = useState('');
    const [seasonPass, setSeasonPass] = useState('');
    const [daysPass, setDaysPass] = useState('');
    const [daysPass2, setDaysPass2] = useState('');
    const [gameId, setGameId] = useState('');
    const [gameDaysPass, setGameDayspass] = useState([]);
    const [gameDaysPass2, setGameDayspass2] = useState([]);

    useEffect(() => {
        getGameSchedule();
        getTicketPass();
    }, []);

    const getGameSchedule = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/schedule_join_all');
            setGameSchedule(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const gameScheduleTable = (game, index) => {
        // style border
        let styleBorder = {};

        // status game
        const gameStatus = ['', 'RESULT', 'NEXT', 'UPCOMING', 'POSTPONED'];
        let statusGame = <div style={{ marginTop: '-5px', lineHeight: '0' }}>{gameStatus[game.status]}</div>;
        let showMobile = 'd-none d-lg-block';

        if (game.status === 1) {
            showMobile = 'd-none d-xl-block';

            if (gameSchedule.length === 1) {
                showMobile = 'd-block';
            } else {
                styleBorder = { borderRight: '2px solid', height: '90px' }
            }
        }

        if (game.status === 2) {
            showMobile = 'd-block';

            if (showMobile === 'd-block d-sm-none') {
                statusGame = <span>{gameStatus[game.status]}</span>;
            }

            if (game.ticket && (game.stream || game.stats)) {
                if (game.stream) {
                    if (game.ticket_passport || game.ticket_biru) {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    } else {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    }
                } else {
                    if (game.ticket_passport || game.ticket_biru) {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    } else {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    }
                }
            } else if (game.ticket) {
                if (game.ticket_passport || game.ticket_biru) {
                    statusGame = <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
                } else {
                    statusGame = <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
                }
            } else if (game.stream) {
                statusGame = <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            } else if (game.stats) {
                statusGame = <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            }
        }

        if (game.status === 3) {
            styleBorder = { borderLeft: '2px solid', height: '90px' }

            if (game.ticket && (game.stream || game.stats)) {
                if (game.stream) {
                    if (game.ticket_passport || game.ticket_biru) {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    } else {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    }
                } else {
                    if (game.ticket_passport || game.ticket_biru) {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    } else {
                        statusGame =
                            <>
                                <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                                <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                            </>;
                    }
                }
            } else if (game.ticket) {
                if (game.ticket_passport || game.ticket_biru) {
                    statusGame = <Button size="sm" className='btnLink' onClick={(e) => handleShow(e, game.ticket, game.ticket_passport, game.ticket_biru, game.id)} style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
                } else {
                    statusGame = <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
                }
            } else if (game.stream) {
                statusGame = <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            } else if (game.stats) {
                statusGame = <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            }
        }

        if (game.status === 4) {
            let dateMatch = new Date(game.match_date);
            let dateNow = new Date();

            if (dateMatch < dateNow) {
                showMobile = 'd-none d-xl-block';
                styleBorder = { borderRight: '2px solid', height: '90px' }
            } else {
                showMobile = 'd-none d-lg-block';
                styleBorder = { borderLeft: '2px solid', height: '90px' }
            }
        }

        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let date = new Date(game.match_date);

        let day = days[date.getDay()];
        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = day + ' ' + m + ' ' + d + ' , ' + y + ' | ' + t + ' WIB';

        return (
            <Col key={index} style={styleBorder} className={showMobile}>
                <Nav>
                    <Nav.Link href='/game-schedules' id='linkGameSchedule'>
                        <table>
                            <tbody>
                                <tr>
                                    <td><img src={process.env.REACT_APP_SITE_IMAGE + game.team1_url} alt='team1' className='team' /></td>
                                    {game.status === 1 ? <td className='header-title fs-1 text-white' style={{ width: '70px' }}>{game.team1_score}</td> : ''}
                                    <td className='p-1 fs-5'>
                                        <img src={process.env.REACT_APP_SITE_IMAGE + game.competition_url} alt='competition' className='competition' />
                                        <h6 style={{ marginTop: '-20px' }}>VS</h6>
                                    </td>
                                    {game.status === 1 ? <td className='header-title fs-1 text-white' style={{ width: '70px' }}>{game.team2_score}</td> : ''}
                                    <td><img src={process.env.REACT_APP_SITE_IMAGE + game.team2_url} alt='team2' className='team' /></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        {statusGame}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>{convertDateTime} | <span style={{ fontSize: '10px' }}>{game.venue}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </Nav.Link>
                </Nav>
            </Col>
        )
    }

    const handleShow = (e, umum = '', passport = '', biru = '', gameId = '') => {
        e.preventDefault();
        setShow(true)
        setLinkUmum(umum);
        setLinkPassport(passport);
        setLinkBiru(biru);
        setGameId(gameId);
    };

    const getTicketPass = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/ticket_pass');
            setSeasonPass(response.data[0].season_pass);
            setDaysPass(response.data[0].days_pass);
            setDaysPass2(response.data[0].days_pass_2);
            setGameDayspass(response.data[0]['game_days_pass'] || []);
            setGameDayspass2(response.data[0]['game_days_pass_2'] || []);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="bg-body-tertiary" id='gameScheduleBg' data-bs-theme="dark"></div>
            <div id='gameSchedule'>
                <Container className='text-center'>
                    <FormLabel className='fs-5 header-title' id='title'>GAME SCHEDULE</FormLabel>
                    <Row>
                        {gameSchedule.map((game, index) => (
                            gameScheduleTable(game, index)
                        ))}
                    </Row>
                </Container>
            </div>

            {seasonPass ?
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={10}>
                            <Row>
                                <Button className='d-block d-lg-none btnLinkTicket header-title fs-5' href={seasonPass} target='_blank'>SEASON PASS</Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                : ''
            }

            <ModalTicket
                show={show}
                close={() => { setShow(false) }}
                linkUmum={linkUmum}
                linkPassport={linkPassport}
                linkBiru={linkBiru}
                daysPass={daysPass}
                daysPass2={daysPass2}
                gameId={gameId}
                gameDaysPass={gameDaysPass}
                gameDaysPass2={gameDaysPass2} />
        </>
    );
}

export default GameSchedule;