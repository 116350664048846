import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ModalTicket = (props) => {
    let ticketDaysPass = false;
    let linkDaysPass = '';
    const gameDaysPass = props.gameDaysPass;
    const gameDaysPass2 = props.gameDaysPass2;

    for (let i = 0; i < gameDaysPass.length; i++) {
        if (parseInt(gameDaysPass[i]) === props.gameId) {
            ticketDaysPass = true;
            linkDaysPass = props.daysPass;
        } else {
            for (let j = 0; j < gameDaysPass2.length; j++) {
                if (parseInt(gameDaysPass2[j]) === props.gameId) {
                    ticketDaysPass = true;
                    linkDaysPass=props.daysPass2;
                }
            }
        }
    }

    return (
        <Modal show={props.show} onHide={props.close} className='modal-ticket' centered>
            <Modal.Header closeButton closeVariant='white'>
                <Modal.Title>
                    <h5>Tiket Grup</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='pb-3 pt-5'>
                <Row>
                    <Col xs={12} className='text-center pb-3'>
                        <Row className='ms-1 me-1'>
                            <Button variant="light" href={props.linkUmum} target='_blank'>Umum</Button>
                        </Row>
                    </Col>
                    {props.linkPassport ?
                        <>
                            <Col xs={12} className='text-center pb-3'>
                                <Row className='ms-1 me-1'>
                                    <Button className='btnPassport' href={props.linkPassport} target='_blank'>Passport Holder</Button>
                                </Row>
                            </Col>
                        </>
                        : ''}
                    {props.linkBiru ?
                        <>
                            <Col xs={12} className='text-center pb-3'>
                                <Row className='ms-1 me-1'>
                                    <Button variant="primary" href={props.linkBiru} target='_blank'>Membersib Biru</Button>
                                </Row>
                            </Col>
                        </>
                        : ''}
                    {linkDaysPass && ticketDaysPass === true ?
                        <Col xs={12} className='text-center pb-3'>
                            <Row className='ms-1 me-1'>
                                <Button variant="light" href={linkDaysPass} target='_blank'>2 Days Pass</Button>
                            </Row>
                        </Col>
                        : ''}
                </Row>
                <p className='pt-3'>Catatan :</p>
                <ul>
                    {props.linkPassport ?
                        <li>Jika Anda memiliki akun <u className='link-offset-2 fw-bold'>Passport Holder</u> di Persib Apps, pilih Passport Holder.</li>
                        : ''}
                    {props.linkBiru ?
                        <li>Jika Anda memiliki akun <u className='link-offset-2 fw-bold'>Membersib Biru</u> di Persib Apps, pilih Membersib Biru.</li>
                        : ''}
                    <li>Jika tidak memiliki akun Persib Apps, pilih Umum.</li>
                </ul>
            </Modal.Body>
        </Modal>
    );
}

export default ModalTicket;